body.light {
  background-color: #f0f0f0;
  color: #333333;
}

body.dark {
  background-color: #1A202C;
  color: #E2E8F0;
}

.navbar-light .navbar-brand, .navbar-light .nav-link {
  color: #333333;
}

.navbar-dark .navbar-brand, .navbar-dark .nav-link {
  color: #E2E8F0;
}

.navbar-light {
  background-color: #ffffff;
}

.navbar-dark {
  background-color: #333333;
}

body.dark .project-card .chakra-heading,
body.dark .project-card .chakra-text {
  color: white !important;
}

body.light .project-card {
  background-color: #ffffff;
  border-color: #e0e0e0;
  color: #333333;
}

body .project-card {
  background-color: #1e1e1e;
  border-color: #444444;
  color: #e0e0e0;
}

.project-card {
  max-width: 300px;
  border-width: 1px;
  border-radius: 0.5rem;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.project-card img {
  width: 100%;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.project-card .card-body {
  padding: 1rem;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #718096;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #4A5568;
}

html {
  scroll-behavior: smooth;
}

::selection {
  background: #4299E1;
  color: white;
}

@keyframes float {
  0% { transform: translateY(0px); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0px); }
}

@keyframes gradient {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.floating {
  animation: float 6s ease-in-out infinite;
}

.gradient-bg {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

@keyframes slideIn {
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.slide-in {
  animation: slideIn 0.5s ease-out forwards;
}

.hover-scale {
  transition: transform 0.3s ease;
}

.hover-scale:hover {
  transform: scale(1.05);
}

:focus {
  outline: 2px solid #4299E1;
  outline-offset: 2px;
}

@media (prefers-color-scheme: dark) {
  :root {
    --bg-color: #1A202C;
    --text-color: #E2E8F0;
  }
}

img {
  content-visibility: auto;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 0;
  background: transparent;
}

.project-card::before {
  content: '';
  position: absolute;
  inset: -1px;
  background: linear-gradient(135deg,
    rgba(66, 153, 225, 0.1),    
    rgba(236, 110, 210, 0.1),   
    rgba(128, 90, 213, 0.1),   
    rgba(49, 151, 149, 0.1)    
  );
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 0;
}

.project-card:hover::before {
  opacity: 1;
}

.project-card::after {
  content: '';
  position: absolute;
  inset: -1px;
  background: radial-gradient(
    800px circle at var(--mouse-x) var(--mouse-y),
    rgba(255, 255, 255, 0.4),
    transparent 40%
  );
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 1;
  pointer-events: none;
  mix-blend-mode: overlay;
}

.project-card:hover::after {
  opacity: 0.9;
}

.error-gradient {
  background: linear-gradient(-45deg, 
    #ff5252, 
    #ff4081, 
    #7c4dff, 
    #448aff  
  );
  background-size: 400% 400%;
  animation: gradientMove 15s ease infinite;
  border-radius: 8px;
  padding: 2px; 
}

.error-gradient::before {
  content: '';
  position: absolute;
  inset: 0;
  background: inherit;
  border-radius: inherit;
  z-index: -1;
  filter: blur(10px);
  opacity: 0.7;
}

@keyframes gradientMove {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.hero-gradient {
  background: linear-gradient(
    300deg,
    rgba(66, 153, 225, 0.9) 0%,
    rgba(236, 110, 210, 0.9) 25%,
    rgba(128, 90, 213, 0.9) 50%,
    rgba(49, 151, 149, 0.9) 75%,
    rgba(66, 153, 225, 0.9) 100%
  );
  background-size: 300% 300%;
  animation: gradientMove 20s ease infinite;
  position: relative;
  isolation: isolate;
}

.hero-gradient::before {
  content: '';
  position: absolute;
  inset: 0;
  background: radial-gradient(
    1500px circle at var(--mouse-x, 50%) var(--mouse-y, 50%),
    rgba(255, 255, 255, 0.2),
    transparent 40%
  );
  opacity: 0;
  transition: opacity 0.5s ease;
  z-index: 1;
}

.hero-gradient:hover::before {
  opacity: 1;
}

.project-card {
  transform-style: preserve-3d;
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}

.project-card:hover {
  transform: translateY(-10px) scale(1.02);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
}

.project-card::after {
  content: '';
  position: absolute;
  inset: -2px;
  background: linear-gradient(
    45deg,
    rgba(66, 153, 225, 0.5),
    rgba(236, 110, 210, 0.5),
    rgba(128, 90, 213, 0.5)
  );
  border-radius: inherit;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: -1;
  filter: blur(10px);
}

.project-card:hover::after {
  opacity: 1;
}

@keyframes gradientMove {
  0% { background-position: 0% 50% }
  50% { background-position: 100% 50% }
  100% { background-position: 0% 50% }
}

button, a, .project-card {
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

::-webkit-scrollbar {
  width: 10px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(
    to bottom,
    rgba(66, 153, 225, 0.8),
    rgba(236, 110, 210, 0.8)
  );
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(
    to bottom,
    rgba(66, 153, 225, 1),
    rgba(236, 110, 210, 1)
  );
}

.hero-section {
  background: linear-gradient(-45deg, 
    #0A2463,  
    #3E92CC,  
    #2A628F,  
    #13293D   
  );
  background-size: 400% 400%;
  animation: gradientBG 15s ease infinite;
  position: relative;
  isolation: isolate;
}

@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.hero-content {
  background: rgba(255, 255, 255, 0.05);
  padding: 3rem;
  border-radius: 1rem;
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
}

.chakra-button {
  transition: all 0.3s ease !important;
}

.chakra-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.chakra-button:active {
  transform: translateY(0);
}

.glowing-text {
  animation: textGlow 3s ease-in-out infinite alternate;
  filter: drop-shadow(0 0 8px rgba(66, 153, 225, 0.3));
}

.typing-text {
  overflow: hidden;
  border-right: 2px solid;
  white-space: nowrap;
  animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;
}

@keyframes textGlow {
  from { filter: drop-shadow(0 0 2px rgba(66, 153, 225, 0.3)); }
  to { filter: drop-shadow(0 0 8px rgba(66, 153, 225, 0.6)); }
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: currentColor }
}

.project-card {
  backdrop-filter: blur(8px);
  transform-style: preserve-3d;
  perspective: 1000px;
}

.project-card:hover {
  transform: translateY(-10px) rotateX(5deg);
  box-shadow: 
    0 20px 40px rgba(0, 0, 0, 0.2),
    0 0 20px rgba(66, 153, 225, 0.2);
}

.gradient-primary {
  background: linear-gradient(135deg,
    #2B6CB0 0%,    
    #4299E1 50%,   
    #63B3ED 100%   
  );
  background-size: 200% 200%;
  animation: gradientMove 15s ease infinite;
}

.gradient-accent {
  background: linear-gradient(135deg,
    #2D3748 0%,    
    #4A5568 50%,   
    #718096 100%  
  );
  background-size: 200% 200%;
  animation: gradientMove 15s ease infinite;
}

.gradient-hero {
  background: linear-gradient(135deg,
    #1A365D 0%,    
    #2B6CB0 25%,   
    #4299E1 50%,   
    #2B6CB0 75%,  
    #1A365D 100%  
  );
  background-size: 400% 400%;
  animation: gradientMove 20s ease infinite;
}

.glass-effect {
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 
    0 8px 32px rgba(0, 0, 0, 0.1),
    0 2px 8px rgba(255, 255, 255, 0.05);
  background: rgba(255, 255, 255, 0.05);
}

.glass-effect:hover {
  border-color: rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.08);
}

.hero-section {
  background: linear-gradient(135deg,
    #1a365d 0%,    
    #2b6cb0 50%,   
    #3182ce 100%   
  );
  position: relative;
  isolation: isolate;
}

.hero-content {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  padding: 3rem;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 
    0 8px 32px rgba(0, 0, 0, 0.1),
    0 2px 8px rgba(255, 255, 255, 0.1);
}

.button-primary {
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: all 0.3s ease;
}

.button-primary:hover {
  background: rgba(255, 255, 255, 0.25);
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.gradient-hero-light {
  background: linear-gradient(135deg,
    rgba(255, 255, 255, 0.9),
    rgba(240, 240, 240, 0.9)
  );
}

.gradient-primary-light {
  background: linear-gradient(135deg,
    rgba(43, 108, 176, 0.8),
    rgba(66, 153, 225, 0.8),
    rgba(144, 205, 244, 0.8)
  );
  background-size: 200% 200%;
  animation: gradientMove 12s ease infinite;
}

.gradient-accent-light {
  background: linear-gradient(135deg,
    rgba(74, 85, 104, 0.7),   
    rgba(113, 128, 150, 0.7), 
    rgba(160, 174, 192, 0.7)   
  );
  background-size: 200% 200%;
  animation: gradientMove 12s ease infinite;
}

.gradient-hero-dark {
  background: linear-gradient(135deg,
    rgba(26, 32, 44, 0.95),
    rgba(45, 55, 72, 0.95)
  );
}

.gradient-primary-dark {
  background: linear-gradient(135deg,
    rgba(26, 54, 93, 0.9),
    rgba(44, 82, 130, 0.9),
    rgba(49, 130, 206, 0.9)
  );
  background-size: 200% 200%;
  animation: gradientMove 12s ease infinite;
}

.gradient-accent-dark {
  background: linear-gradient(135deg,
    rgba(45, 55, 72, 0.9),     
    rgba(74, 85, 104, 0.9),   
    rgba(113, 128, 150, 0.9)   
  );
  background-size: 200% 200%;
  animation: gradientMove 12s ease infinite;
}

.glass-effect {
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 
    0 8px 32px rgba(0, 0, 0, 0.1),
    0 2px 8px rgba(255, 255, 255, 0.05);
  background: rgba(255, 255, 255, 0.05);
}

.glass-effect:hover {
  border-color: rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.08);
}

.typed-text {
  color: #4299E1;
  font-weight: 600;
}

.cursor {
  display: inline-block;
  margin-left: 2px;
  animation: blink 1s infinite;
  color: currentColor;
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.gradient-hero-light {
  background: linear-gradient(135deg,
    rgba(255, 255, 255, 0.9),
    rgba(240, 240, 240, 0.9)
  );
}

.gradient-hero-dark {
  background: linear-gradient(135deg,
    rgba(26, 32, 44, 0.95),
    rgba(45, 55, 72, 0.95)
  );
}

.glass-card {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  transition: all 0.3s ease;
}

.glass-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.hero-section {
  min-height: 60vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-size: 200% 200%;
}

.content-section {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.hover-lift {
  transition: transform 0.2s ease;
}

.hover-lift:hover {
  transform: translateY(-2px);
}

.project-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  padding: 1rem;
}

@media (max-width: 1200px) {
  .project-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .project-grid {
    grid-template-columns: 1fr;
  }
}

.site-background {
  position: fixed;
  inset: 0;
  z-index: -1;
  pointer-events: none;
  background: linear-gradient(
    135deg,
    rgba(66, 153, 225, 0.05) 0%,
    rgba(128, 90, 213, 0.05) 50%,
    rgba(236, 110, 210, 0.05) 100%
  );
  backdrop-filter: blur(60px);
}

.background-grid {
  background-image: 
    linear-gradient(var(--chakra-colors-blue-100) 1px, transparent 1px),
    linear-gradient(90deg, var(--chakra-colors-purple-100) 1px, transparent 1px);
  background-size: 50px 50px;
  opacity: 0.15;
  mask-image: radial-gradient(circle at center, black 40%, transparent 80%);
}

.hero-section {
  position: relative;
  overflow: hidden;
}

.hero-section::before {
  content: '';
  position: absolute;
  inset: -20%;
  background: radial-gradient(
    circle at 50% 50%,
    rgba(66, 153, 225, 0.1),
    rgba(128, 90, 213, 0.1),
    rgba(236, 110, 210, 0.1),
    transparent 70%
  );
  filter: blur(60px);
  z-index: -1;
}

.gradient-blur {
  position: absolute;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: rgba(66, 153, 225, 0.2);
  filter: blur(40px);
  animation: float 8s ease-in-out infinite;
}

.gradient-blur:nth-child(2) {
  background: rgba(128, 90, 213, 0.2);
  animation-delay: -2s;
}

@keyframes float {
  0%, 100% { transform: translate(0, 0); }
  50% { transform: translate(20px, -20px); }
}

.hero-container {
  position: relative;
  overflow: hidden;
  padding: 2rem 0;
}

.glass-card {
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 
    0 8px 32px rgba(0, 0, 0, 0.1),
    0 2px 8px rgba(255, 255, 255, 0.05);
}

.gradient-blur {
  position: absolute;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  filter: blur(80px);
  opacity: 0.5;
  pointer-events: none;
}

.gradient-blur.primary {
  background: radial-gradient(circle at center,
    rgba(66, 153, 225, 0.3),
    rgba(49, 130, 206, 0.1)
  );
  top: -50px;
  left: -50px;
  animation: float 10s ease-in-out infinite;
}

.gradient-blur.secondary {
  background: radial-gradient(circle at center,
    rgba(128, 90, 213, 0.3),
    rgba(144, 205, 244, 0.1)
  );
  bottom: -50px;
  right: -50px;
  animation: float 10s ease-in-out infinite reverse;
}

.glowing-text {
  text-shadow: 0 0 20px rgba(66, 153, 225, 0.3);
}

.hover-lift {
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.hover-lift:hover {
  transform: translateY(-4px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
}

.background-grid {
  background-image: 
    linear-gradient(rgba(255, 255, 255, 0.05) 1px, transparent 1px),
    linear-gradient(90deg, rgba(255, 255, 255, 0.05) 1px, transparent 1px);
  background-size: 30px 30px;
  mask-image: radial-gradient(circle at center, black 30%, transparent 70%);
}

body.light .site-background {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.1) 1px,
    transparent 1px
  ),
  linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.1) 1px,
    transparent 1px
  );
  background-size: 40px 40px;
  background-color: #f8fafc;
}

.background-grid {
  background-image: 
    linear-gradient(var(--chakra-colors-gray-200) 1px, transparent 1px),
    linear-gradient(90deg, var(--chakra-colors-gray-200) 1px, transparent 1px);
  background-size: 30px 30px;
  opacity: 0.3;
}

.glass-card {
  background: rgba(255, 255, 255, 0.05) !important;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 
    0 8px 32px rgba(0, 0, 0, 0.1),
    0 2px 8px rgba(255, 255, 255, 0.05);
}

body.light .glass-card {
  background: rgba(255, 255, 255, 0.8) !important;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 
    0 8px 32px rgba(0, 0, 0, 0.05),
    0 2px 8px rgba(0, 0, 0, 0.05);
}

.gradient-blur.primary {
  opacity: 0.3;
  background: radial-gradient(circle at center,
    var(--chakra-colors-blue-400),
    transparent
  );
}

.gradient-blur.secondary {
  opacity: 0.3;
  background: radial-gradient(circle at center,
    var(--chakra-colors-purple-400),
    transparent
  );
}

body.dark .site-background {
  background: linear-gradient(
    135deg,
    rgba(26, 32, 44, 0.95),
    rgba(45, 55, 72, 0.95)
  );
}

.skill-card {
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
}

.skill-card:hover {
  background: rgba(255, 255, 255, 0.06);
  border-color: rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
  box-shadow: 
    0 10px 20px rgba(0, 0, 0, 0.1),
    0 0 10px rgba(99, 102, 241, 0.1);
}

/* Modern gradient color palette */
:root {
  --gradient-1: linear-gradient(135deg, #6366f1 0%, #8b5cf6 50%, #d946ef 100%);
  --gradient-2: linear-gradient(135deg, #3b82f6 0%, #8b5cf6 50%, #ec4899 100%);
  --gradient-3: linear-gradient(135deg, #0ea5e9 0%, #6366f1 50%, #d946ef 100%);
  --gradient-hover: linear-gradient(135deg, #2563eb 0%, #7c3aed 50%, #db2777 100%);
}

.hero-section {
  background: var(--gradient-1);
  background-size: 400% 400%;
  animation: gradientBG 15s ease infinite;
}

.glass-card {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(12px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 
    0 8px 32px rgba(0, 0, 0, 0.1),
    0 2px 8px rgba(0, 0, 0, 0.1);
}

.skill-card {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
}

.skill-card:hover {
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.3);
  transform: translateY(-5px);
  box-shadow: 
    0 20px 40px rgba(0, 0, 0, 0.1),
    0 0 20px rgba(99, 102, 241, 0.2);
}

.project-card::before {
  background: var(--gradient-2);
  opacity: 0;
  transition: opacity 0.3s;
}

.project-card:hover::before {
  opacity: 0.1;
}

::-webkit-scrollbar-thumb {
  background: var(--gradient-3);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--gradient-hover);
}

.glowing-text {
  background: var(--gradient-1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  filter: drop-shadow(0 0 8px rgba(99, 102, 241, 0.3));
}

@keyframes gradientBG {
  0% { background-position: 0% 50% }
  50% { background-position: 100% 50% }
  100% { background-position: 0% 50% }
}

.gradient-theme {
  background: linear-gradient(135deg,
    #0A2463 0%,    /* Deep Blue */
    #3E92CC 25%,   /* Light Blue */
    #2A628F 50%,   /* Medium Blue */
    #13293D 75%,   /* Dark Blue */
    #16324F 100%   /* Navy Blue */
  );
  background-size: 400% 400%;
  animation: gradientMove 20s ease infinite;
}

.glass-card {
  background: rgba(255, 255, 255, 0.05) !important;
  backdrop-filter: blur(12px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 
    0 8px 32px rgba(10, 36, 99, 0.1),
    0 2px 8px rgba(62, 146, 204, 0.05);
}

.skill-card {
  background: linear-gradient(
    135deg,
    rgba(10, 36, 99, 0.05),
    rgba(62, 146, 204, 0.05),
    rgba(42, 98, 143, 0.05)
  ) !important;
  backdrop-filter: blur(12px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
}

.skill-card:hover {
  background: linear-gradient(
    135deg,
    rgba(10, 36, 99, 0.1),
    rgba(62, 146, 204, 0.1),
    rgba(42, 98, 143, 0.1)
  ) !important;
  transform: translateY(-5px);
  box-shadow: 
    0 20px 40px rgba(10, 36, 99, 0.1),
    0 0 20px rgba(62, 146, 204, 0.1);
}


